import React, { useState, Fragment, useEffect } from 'react';
import s from './style.scss';
import s1 from './new_style.scss';

import { Formik, Form } from 'formik';
import { Form as BForm, Button, Container, Row, Col, Card } from 'react-bootstrap';
import LogoBrand from '../../LogoBrand';
import MaskedInput from '../../AppComponents/MaskedInput';
import { LOGINVALIDATION } from '../../../config/FormSchemas';
import { withFirebase } from '../../../auth/firebase';
import { store } from 'react-notifications-component';
import {view} from 'react-easy-state'
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import { appEasyState } from '../../../easyStateStore';

const Login = (props) => {
  const { user, firebase } = props;
  const {
    history: { push },
  } = props;

  const [loading, setLoading] = useState(false)


  const isExistedUser = (userEmail) => {
    props.firebase
      .isUserAlreadyExists(userEmail)
      .then((userSignInMethods) => {
        if (userSignInMethods.length === 1) {
        } else {
          setLoading(false);
          store.addNotification({
            title: 'Error',
            message: 'This user does not exist.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        }
        return;
      })
      .catch((error) => {
        store.addNotification({
          title: 'Error',
          message: error,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        console.log('error ==> ', error);
        return;
      });
  };

  return (
    <Fragment>
      <Formik
        initialErrors={{ email: 'Not Valid' }}
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LOGINVALIDATION}
        onSubmit={({ email, password }) => {
          console.log('before getUserPhoneNumberAgainstEmail')
          const email_lowercase = email.toLowerCase()
          setLoading(true)

          props.firebase
            .isUserAlreadyExists(email_lowercase)
            .then((userSignInMethods) => {
              if (userSignInMethods.length === 1) {

                props.firebase
                  .getUserPhoneNumberAgainstEmail(email_lowercase)
                  .then(({ phoneNumber, twoFactorAuthentication, sealsState }) => {

                    appEasyState.login = {
                      email: email_lowercase,
                      password,
                      phoneNumber,
                    }
                    console.log('after login',twoFactorAuthentication, sealsState, phoneNumber)
                    if (sealsState === true && twoFactorAuthentication) {
                      // push('verification', {
                      //   phoneNumber: phoneNumber,
                      //   email,
                      //   password,
                      // });


                      props.setLoginCardCompleted(true)
                      return;
                    } else if (twoFactorAuthentication === false) {

                      console.log('after 2fa off')
                      props.firebase
                        .doSignInWithEmailAndPassword(email_lowercase, password)
                        .then((res) => {
                          console.log('res =======> ', res);
                          // debugger
                          if (!res.user.emailVerified) {
                            setLoading(false);
                            store.addNotification({
                              title: 'Error',
                              message: 'Please verify your email to continue.',
                              type: 'danger',
                              ...NOTIFICATION_PROPS,
                            });
                            props.firebase.doSignOut()
                              .then((signoutRes) => {
                                console.log('logout 5 called')
                                localStorage.setItem('encryptedString', 'null')
                                localStorage.setItem('userId', 'null')
                                push('/login');

                                return;
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }
                          else {
                            console.log('in dashboard')
                            localStorage.setItem('userId', res.user.uid)
                            localStorage.setItem('encryptedString', appEasyState.getEncryptedString(res.user.uid, 'sealpact-origami/crewlogix'))
                            localStorage.setItem('isFreshLogin', 'false')
                            setTimeout(() => {
                              setLoading(false);
                              window.location.href = '/dashboard'
                            }, 1500)
                          }
                          return;
                        })
                        .catch((error) => {
                          console.log('error in login', error);
                          setLoading(false);
                          store.addNotification({
                            title: 'Error',
                            message: error.message,
                            insert: "top",
                            type: 'danger',
                            ...NOTIFICATION_PROPS,
                          });
                        });
                    } else {
                      // push('verification', {
                      //   phoneNumber: phoneNumber,
                      //   email,
                      //   password,
                      // });

                      props.setLoginCardCompleted(true)

                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.log('error => ', error);
                    store.addNotification({
                      title: 'Error',
                      message: error.message,
                      type: 'danger',
                      ...NOTIFICATION_PROPS,
                    });
                  });


              } else {
                setLoading(false);
                store.addNotification({
                  title: 'Error',
                  message: 'This user does not exist.',
                  type: 'danger',
                  ...NOTIFICATION_PROPS,
                });
              }
              return;
            })
            .catch((error) => {
              setLoading(false)
              store.addNotification({
                title: 'Error',
                message: error.message,
                type: 'danger',
                ...NOTIFICATION_PROPS,
              });
              console.log('error ==> ', error);
              return;
            });

        }}
      >
        {(formikProps) => {
          //
          const { handleSubmit, errors, isSubmitting, handleChange } =
            formikProps;
          const hasErrors = Object.keys(errors).length > 0;
          return (
            <Form onSubmit={handleSubmit} className={s1.backgroundImage}>
              <Container className={s1.wrapper}>
                <div className={s1.headerLogo}>
                  <LogoBrand imgSize={48} className={s1.logoStyle} {...props} />
                </div>
                <div className={s1.centerClass}>
                  <div className={`${s1.marginForm}`}>
                    <div id="Login" className="d-flex justify-content-center align-items-center">
                      <Card className={s1.onboardingCard}>
                        <div className={s1.wrapper}>
                          <Card.Title><span className={s1.cardTitle}>Login to SealPact</span></Card.Title>
                          <Card.Subtitle className={s1.cardSubtitle}>Please enter your email and password to login</Card.Subtitle>
                          <Card.Body style={{ padding: '0' }}>
                            <Row style={{ marginTop: '32px' }}>
                              <Col>
                                <div>
                                  <MaskedInput
                                    name="email"
                                    formikProps={formikProps}
                                    label="Email"
                                    placeholder='example@email.com'
                                    stopHandleBlur={true}
                                  />
                                  <MaskedInput
                                    name="password"
                                    type="password"
                                    formikProps={formikProps}
                                    label="Password"
                                    placeholder='Your password'
                                    stopHandleBlur={true}
                                  />
                                </div>
                                <div className='text-right'>
                                  <span
                                    className={`${s1.forgot} ml-auto`}
                                    onClick={() => push('forgotPassword')}
                                  >
                                    Forgot Password?
                                  </span>
                                </div>

                                <Button
                                  variant="primary"
                                  disabled={hasErrors || isSubmitting}
                                  type="submit"
                                  className={s.button}
                                  block
                                  // onClick={() => isExistedUser(formikProps.values.email)}
                                >
                                  {loading ? (
                                    <div>
                                      <span className="spinner-border spinner-border-sm mr-2"/>
                                      Logging in
                                    </div>
                                  ) : (
                                    <div>
                                      Login
                                    </div>
                                  )}

                                </Button>
                                <p className={s.footerText}>
                                  Not a member yet?&nbsp;
                                  <span
                                    onClick={() => push('signup')}
                                    style={{ cursor: 'pointer', color: '#04B5FF', fontWeight: 500 }}
                                  >
                                  Sign up
                                </span>
                                </p>
                              </Col>
                            </Row>
                          </Card.Body>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default withFirebase(view(Login));