/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import s from './styles.scss';
import CustomTooltip from '../../AppComponents/CustomTooltip';

const MaskedInput = ({
  formikProps,
  name,
  label,
  placeholder = '',
  type = 'text',
  style,
  autoComplete,
  onBlur,
  onTextChange,
  maxLength,
  disabled,
  autoFocus,
  content,
  value,
  isMandatory,
  stopHandleBlur
}) => {
  const { touched, errors, handleChange, handleBlur } = formikProps;
  const handleTextChange = (val) => {
    console.log('masked val', val)
    handleChange(val);
    if (onTextChange !== undefined) {
      onTextChange(val);
    }
  };
  useEffect(() => {
    console.log('masked', {
      formikProps,
      name,
      label,
      style,
      autoComplete,
      onBlur,
      onTextChange,
      maxLength,
      disabled,
      autoFocus,
      content,
      value,
    })
  }, [])
  return label === 'Mobile Number' || label === 'Phone Number' ||
  label === 'Primary Email' || label === 'Administrator Email' || label === 'Website' ? (
    <Form.Group className={s.inputGroup} controlId={name}>
      <Form.Label className={style || s.boldLabel}>{label}<span style={{ color: '#B30021' }}>{isMandatory ? '*' : ''}</span></Form.Label>
      <CustomTooltip trigger={'hover'} location={'auto'} content={content}>
        <i
          style={{ marginLeft: '3px', marginTop: '3px' }}
          className="fa fa-info-circle"
          aria-hidden="true"
        ></i>
      </CustomTooltip>
      {label === 'Mobile Number' ? (
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
          <Form.Control
            onChange={(val) => handleTextChange(val)}
            defaultValue={value || (formikProps ? formikProps.values ? (formikProps.values[name] || null)  : null : null)}
            type={type}
            autoFocus={autoFocus}
            maxLength={maxLength}
            disabled={disabled}
            autoComplete={autoComplete}
            onBlur={onBlur || (!stopHandleBlur && handleBlur)}
            placeholder={placeholder}
            className={`${s.inputField} form-control ${
              errors[name] && touched[name] ? ' is-invalid' : ''
            }`}
          />

          <ErrorMessage name={name} className="invalid-feedback" component="div" />
        </InputGroup>
      ) : (
        <>
          <Form.Control
            onChange={(val) => handleTextChange(val)}
            defaultValue={value || (formikProps ? formikProps.values ? (formikProps.values[name] || null)  : null : null)}
            type={type}
            autoFocus={autoFocus}
            maxLength={maxLength}
            disabled={disabled}
            autoComplete={autoComplete}
            onBlur={onBlur || (!stopHandleBlur && handleBlur)}
            placeholder={placeholder}
            className={`${s.inputField} form-control ${
              errors[name] && touched[name] ? ' is-invalid' : ''
            }`}
          />

          <ErrorMessage name={name} className="invalid-feedback" component="div" />
        </>
      )}
    </Form.Group>
  ) : (
    <Form.Group className={s.inputGroup} controlId={name}>
      <Form.Label className={style || s.boldLabel}>{label}<span style={{ color: '#B30021' }}>{isMandatory ? '*' : ''}</span></Form.Label>
      {content ? (
        <CustomTooltip content={content}>
          <Form.Control
            onChange={(val) => handleTextChange(val)}
            defaultValue={value || (formikProps ? formikProps.values ? (formikProps.values[name] || null)  : null : null)}
            type={type}
            autoFocus={autoFocus}
            maxLength={maxLength}
            disabled={disabled}
            autoComplete={autoComplete}
            onBlur={onBlur || (!stopHandleBlur && handleBlur)}
            placeholder={placeholder}
            className={`${s.inputField} form-control ${
              errors[name] && touched[name] ? ' is-invalid' : ''
            }`}
          />
        </CustomTooltip>
      ) : (
        <Form.Control
          onChange={(val) => handleTextChange(val)}
          defaultValue={value || (formikProps ? formikProps.values ? (formikProps.values[name] || null)  : null : null)}
          type={type}
          autoFocus={autoFocus}
          maxLength={maxLength}
          disabled={disabled}
          autoComplete={autoComplete}
          onBlur={onBlur || (!stopHandleBlur && handleBlur)}
          placeholder={placeholder}
          className={`${s.inputField} form-control ${
            errors[name] && touched[name] ? ' is-invalid' : ''
          }`}
        />
      )}

      <ErrorMessage name={name} className="invalid-feedback" component="div" />
    </Form.Group>
  );
};

export default MaskedInput;
